<template>
  <div element-loading-text=""
       element-loading-background="rgba(0, 0, 0, 0.3)"
       class="orderPage"
       v-loading.fullscreen.lock="fullscreenLoading">
    <HomePageTop ref="tyop"></HomePageTop>
    <temporaryOrderDetailsPage v-if="orderStatusName==='temporary'||orderStatusName==='offer'"
                               :orderStatusName="orderStatusName" :orderDetailsInfo="orderDetailsInfo"/>
    <obligationOrderDetailsPage v-if="orderStatusName==='obligation'" :orderStatusName="orderStatusName"
                                :orderDetailsInfo="orderDetailsInfo" ref="obligationOrderDetailsPage"/>
    <purchaseOrderDetailsPage v-if="orderStatusName==='purchase'" :orderDetailsInfo="orderDetailsInfo"/>
    <div class="technicalServiceSupport">
      <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
    </div>
  </div>
</template>
<script>
import HomePageTop from "../../../../../components/head/HomePageTop.vue";
import SpecialNoteVue from "./newCom/SpecialNote.vue";
import FootVue from "../../../../../components/foot/Foot.vue";
import temporaryOrderDetailsPage from './components/temporaryOrderDetailsPage'
import obligationOrderDetailsPage from './components/obligationOrderDetailsPage'
import purchaseOrderDetailsPage from "@/views/UserCenter/views/userOrder/views/components/purchaseOrderDetailsPage";

export default {
  data() {
    return {
      fullscreenLoading: true,
      orderDetailsInfo: {},
      orderStatusName: '',
    };
  },
  // customerPrompt,
  components: {
    HomePageTop,
    SpecialNoteVue,
    FootVue,
    temporaryOrderDetailsPage,
    obligationOrderDetailsPage,
    purchaseOrderDetailsPage,
  },

  created() {
    this.pageLoad();
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    handleClose(done) {
      this.$confirm(`${this.$fanyi("确认关闭")}？`)
      .then(_ => {
        done();
      })
      .catch(_ => {
      });
    },
    pageLoad() {
      // 判断是购物车还是订单列表跳转的页面
      if (this.$route.query.id) {
        this.orderStatusName = 'temporary';
        this.fullscreenLoading = false;
      } else {
        this.$api.orderDetail({
          order_sn: this.$route.query.order_sn,
        }).then((res) => {
          if (res.code != 0) {
            this.$message.error(this.$fanyi(res.msg));
            setTimeout(() => {
              window.history.go(-1)
            }, 1500)
            return
          }
          if (res.data === []) {
            this.$message.error(this.$fanyi(res.msg));
            return
          }
          if (res.data.amazon_authorization == undefined) {
            res.data.amazon_authorization = false;
          }
          this.orderDetailsInfo = res.data;
          if (res.data.status > 30 && (res.data.status != 40 && (res.data.part_pay_status === 0 || res.data.part_pay_status == 9))) {
            this.orderStatusName = 'purchase';
          } else {
            if (res.data.is_y_editing == 1 && res.data.status != 30) {
              this.orderStatusName = 'offer';
            } else {
              if (res.data.part_pay_status > 0) {
                this.orderStatusName = 'obligation';
              }
              switch (res.data.status) {
                case 10:
                case 20:
                  this.orderStatusName = 'temporary';
                  break;
                case -2:
                case 22:
                case 21:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                  this.orderStatusName = 'offer';
                  break;
                case 30:
                case 40:
                  this.orderStatusName = 'obligation';
                  break;
              }
            }
          }
          if (res.data.status == -2 && res.data.is_pay == true) {
            this.orderStatusName = 'purchase';
          }
          this.fullscreenLoading = false;
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-popover.el-popper.dditionalServicesPro {
  background-color: transparent;
  box-shadow: none;
  border: none;

  .popper__arrow {
    display: none;
  }
}

button {
  cursor: pointer;
}
</style>
<style lang='scss' scoped>
@import "../../../../../css/mixin.scss";
@import "./css/orderDetails.scss";

.technicalServiceSupport {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  div {
    width: 600px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #333333;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
