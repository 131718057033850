<template>
  <div class="inBatchesPaymentDetailsPage">
    <div :class="$parent.datas.part_pay_status==1?'active':''" class="left">
      <div class="label">{{ $fanyi('首款') }}</div>
      <div class="priceDetails">
        <div>商品金額</div>
        <div>{{ $parent.datas.order_part_pay[0].goods_amount }} 元</div>
        <div>
          {{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[0].goods_amount) * Number(exchangeRate)).toFixed(0))
          }}
          円
        </div>
      </div>
      <div class="priceDetails">
        <div>手数料</div>
        <div>{{ $parent.datas.order_part_pay[0].service_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[0].service_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>オプション手数料</div>
        <div>{{ $parent.datas.order_part_pay[0].option_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[0].option_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>中国内送料</div>
        <div>{{ $parent.datas.order_part_pay[0].freight_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[0].freight_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>その他</div>
        <div>{{ $parent.datas.order_part_pay[0].other_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[0].other_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>合計金額</div>
        <div>{{ $parent.datas.order_part_pay[0].rmbTotal.toFixed(2) }} 元</div>
        <div>{{ $fun.yenNumSegmentation($parent.datas.order_part_pay[0].yenTotal) }} 円</div>
      </div>
    </div>
    <div :class="$parent.datas.part_pay_status>1?'active':''" class="right">
      <div class="label">{{ $fanyi('尾款') }}</div>
      <div class="priceDetails">
        <div>商品金額</div>
        <div>{{ $parent.datas.order_part_pay[1].goods_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[1].goods_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>手数料</div>
        <div>{{ $parent.datas.order_part_pay[1].service_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[1].service_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>オプション手数料</div>
        <div>{{ $parent.datas.order_part_pay[1].option_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[1].option_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>中国内送料</div>
        <div>{{ $parent.datas.order_part_pay[1].freight_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[1].freight_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>その他</div>
        <div>{{ $parent.datas.order_part_pay[1].other_amount }} 元</div>
        <div>{{
            $fun.yenNumSegmentation((Number($parent.datas.order_part_pay[1].other_amount) * Number(exchangeRate)).toFixed(0))
          }} 円
        </div>
      </div>
      <div class="priceDetails">
        <div>合計金額</div>
        <div>{{ $parent.datas.order_part_pay[1].rmbTotal.toFixed(2) }} 元</div>
        <div>{{ $fun.yenNumSegmentation($parent.datas.order_part_pay[1].yenTotal) }} 円</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
}
</script>

<style lang="scss" scoped>
.inBatchesPaymentDetailsPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  margin: 0 auto;

  .left, .right {
    width: 685px;
    border-radius: 10px;
    padding: 30px 0 30px 50px;
    box-sizing: border-box;
    border: none;
    background: #fff;

    .label {
      color: #333333;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 28px;
    }
  }

  .active {
    border: 1px dashed #B4272B;
    background: #FAF2F2;
  }

  .priceDetails {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    div {
      color: #333333;
      font-size: 14px;
    }

    div:first-child, div:nth-child(2) {
      min-width: 112px;
      margin-right: 60px;
    }
  }

  .priceDetails:last-child {
    margin-bottom: 0;
  }
}
</style>