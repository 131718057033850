<template>
  <div>
    <div class="selectAlternativeLinkTopText">{{ $fanyi("备选链接选择：") }}</div>
    <div>{{ $fanyi("当前链接：") }}</div>
    <div style="border-bottom: 1px solid #797979;width: 740px;">
      <ul class="flexAndCenter goodsMessageHeader">
        <li class="mirror">{{ $fanyi("写真") }}</li>
        <li class="goods">{{ $fanyi("商品详细") }}</li>
        <li class="mirror">{{ $fanyi("発注数") }}</li>
        <li class="mirror">{{ $fanyi("在库数") }}</li>
        <li class="mirror">{{ $fanyi("单价(元)") }}</li>
        <li class="mirror">{{ $fanyi("国内送料") }}</li>
        <li class="mirror">{{ $fanyi("小计(元)") }}</li>
        <li class="goods">{{ $fanyi("スタッフ") }}</li>
      </ul>
      <ul class="flexAndCenter goodsMessageBottom">
        <!--        写真-->
        <li class="mirror">
          <el-popover placement="right" trigger="hover">
            <img
                :src="goodsInfo.pic"
                @click="$fun.toCommodityDetails(goodsInfo.goods_id,goodsInfo.from_platform)"
                style="width: 300px; height: 300px"/>
            <img
                :src="goodsInfo.pic"
                slot="reference"
                style="width: 50px;height: 50px"/>
          </el-popover>
        </li>
        <!--        商品详细-->
        <li class="goods" style="height: 80px;overflow: hidden">
          <div class="showDetail " style="padding-top: 10px">
            <el-popover trigger="hover">
              <div class="showDetailCon u-line">
                <p v-for="(bitem, bindex) in goodsInfo.detail"
                   :key="bitem.key + bindex"
                   :title="bitem.key + ':' + bitem.value"
                   class="goodsDetailOpt ispay">
                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                </p>
              </div>
              <div class="showDetailCon" slot="reference">
                <p v-for="(bitem, bindex) in goodsInfo.detail"
                   :key="bitem.key + bindex"
                   :title="bitem.key + ':' + bitem.value"
                   class="goodsDetailOpt ispay">
                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                </p>
              </div>
            </el-popover>
          </div>
        </li>
        <li class="mirror">{{ goodsInfo.is_now_data.num }}</li><!--        発注数-->
        <li class="mirror">{{ goodsInfo.is_now_data.num }}</li><!--        在库数-->
        <li class="mirror">{{ goodsInfo.is_now_data.price }}</li><!--        单价(元)-->
        <li class="mirror">{{ goodsInfo.is_now_data.freight }}</li><!--        国内送料-->
        <li class="mirror">{{ $fun.ceil(goodsInfo.totalPrice) }}</li><!--        小计(元)-->
        <li class="goods">{{ goodsInfo.y_reply }}</li><!--        スタッフ-->
      </ul>
    </div>
    <div style="margin-top: 30px">
      {{ $fanyi("备选链接：") }}
    </div>
    <div style="width: 838px;">
      <ul class="flexAndCenter alternativeGoodsHeader">
        <li class="mirror">{{ $fanyi("写真") }}</li>
        <li class="goods">{{ $fanyi("商品详细") }}</li>
        <li class="mirror">{{ $fanyi("発注数") }}</li>
        <li class="mirror">{{ $fanyi("在库数") }}</li>
        <li class="mirror">{{ $fanyi("单价(元)") }}</li>
        <li class="mirror">{{ $fanyi("国内送料") }}</li>
        <li class="mirror">{{ $fanyi("小计(元)") }}</li>
        <li class="goods">{{ $fanyi("スタッフ") }}</li>
        <li class="operation">{{ $fanyi("操作") }}</li>
      </ul>
      <ul class="flexAndCenter alternativeGoodsBottom" v-for="(item,index) in goodsInfo.order_detail_replace"
          :key="index">
        <li class="mirror">
          <el-popover placement="right" trigger="hover">
            <img :src="item.pic"
                 @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                 style="width: 300px; height: 300px"/>
            <img :src="item.pic"
                 slot="reference"
                 style="width: 50px;height: 50px"/>
          </el-popover>
        </li>
        <li class="goods">
          <div class="showDetail">
            <el-popover trigger="hover">
              <div class="showDetailCon u-line">
                <p v-for="(bitem, bindex) in item.detail"
                   :key="bitem.key + bindex"
                   :title="bitem.key + ':' + bitem.value"
                   class="goodsDetailOpt ispay">
                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                </p>
              </div>
              <div class="showDetailCon" slot="reference" style="width:160px;min-height:79px;">
                <p v-for="(bitem, bindex) in item.detail"
                   :key="bitem.key + bindex"
                   :title="bitem.key + ':' + bitem.value"
                   class="goodsDetailOpt ispay">
                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                </p>
              </div>
            </el-popover>
          </div>
        </li>
        <li class="mirror">{{ item.confirm_num }}</li>
        <li class="mirror"><span>{{ item.confirm_num }}</span></li>
        <li class="mirror">{{ item.confirm_price }}</li>
        <li class="mirror">{{ item.confirm_freight }}</li>
        <li class="mirror">{{ $fun.ceil(item.totalPrice) }}</li>
        <li class="goods">{{ item.y_reply }}</li>
        <li class="operation">
          <el-button type="primary" size="small" @click="confirmReplaceOrderGoods(item.id)">
            {{ $fanyi("选择该商品") }}
          </el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectAlternativeLink",
  props: {
    goodsInfo: {
      type: Object
    }
  },
  methods: {
    confirmReplaceOrderGoods(id) {
      this.$emit("confirmReplaceOrderGoods", id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin.scss";
@import "../css/orderDetails.scss";
</style>
