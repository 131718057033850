<template>
  <div element-loading-text=""
       element-loading-background="rgba(0, 0, 0, 0.3)"
       class="orderPage"
       v-loading.fullscreen.lock="fullscreenLoading">
    <div class="main zhu base-w">
      <!-- 头部 -->
      <div class="head">
        <span class="flexAndCenter"><font class="headT1">{{ $fanyi("注文NO") }}</font><span
            class="headT1">:{{ datas.order_sn }}</span></span>
        <div class="flexAndCenter">
          <div class="headerSearchContainer flexAndCenter">
            <div class="inputContainer flexAndCenter">
              <el-input v-model="input" :placeholder="$fanyi('请输入想搜索的URL')" style="width:340px"/>
            </div>
            <i class="el-icon-search" @click="linkSearch"></i>
          </div>
        </div>
      </div>
      <!-- 订单列表 -->
      <div class="obligationOrder">
        <ul class="flexAndCenter obligationOrderHeader">
          <li class="inputBox">{{ $fanyi("选择") }}</li>
          <li class="mirror">No</li>
          <li class="mirror">{{ $fanyi("写真") }}</li>
          <li class="goods" style="width: 200px">{{ $fanyi("商品规格") }}</li>
          <li class="quantityOrdered">{{ $fanyi("订购数量") }}</li>
          <li class="quantityOrdered"><span>{{ $fanyi("在库数") }}</span></li>
          <li class="price">{{ $fanyi("单价(元)") }}</li>
          <li class="theDomesticFeed">{{ $fanyi("国内送料") }}</li>
          <li class="theDomesticFeed" v-show="updateOrderStatus===true">{{ $fanyi("小计(元)") }}</li>
          <li v-show="updateOrderStatus===false" class="theDomesticFeed">
            {{ $fanyi("成本(元)") }}
          </li>
          <li class="optionSelect">
            <div>{{ $fanyi("オプション") }}</div>
            <el-popover trigger="hover" placement="top">
              <div style="width: 320px">
                <div style="margin-bottom: 20px">
                  下記記載のサービスはあくまで基本的なもので、
                </div>
                <div style="margin-bottom: 20px">
                  記載されていないサービスなど、新規需要などあれば担当者までご相談していただき、
                </div>
                <div>対応可能か個別で確認返答させていただきます。</div>
              </div>
              <img src="../../../../../../assets/4.png" style="margin:6px 0 0 6px" slot="reference">
            </el-popover>
          </li>
          <li class="tagName">{{ $fanyi("标签编号") }}</li>
          <li class="remark">{{ $fanyi("备考栏") }}</li>
          <li :style="updateOrderStatus===true?'width:140px':''" class="worker">{{ $fanyi("スタッフ") }}</li>
          <!--          <li class="optionalLink">{{ $fanyi("备选链接") }}</li>-->
        </ul>
        <transition-group tag="div" class="goodsBox">
          <ul v-for="(item,itemIndex) in newList" :key="item.id"
              :style="updateOrderStatus===false&&(item.is_now_data.num<item.is_client_send_data.num)?'background: #faf2f2':''"
          >
            <!-- 全选 -->
            <li class="inputBox" style="position: relative">
              <div class="flexAndCenterAndCenter" style="flex-direction: column">
                <img v-show="updateOrderStatus===true"
                     :draggable="updateOrderStatus===true"
                     alt=""
                     class="moveIcon cursorPointer"
                     src="../../../../../../assets/moveIcon.svg"
                     @dragend="handleDragEnd($event, item)"
                     @dragenter="handleDragEnter($event, item)"
                     @dragstart="handleDragStart($event, item)" @dragover.prevent="handleDragOver($event, item)">
                <el-popover placement="right" title="合计数量" trigger="focus" width="200">
                  <statistics :checkedList="checkedList" :edit="updateOrderStatus"/>
                  <el-checkbox slot="reference"
                               v-model="item.checked"
                               style="margin:0"
                               type="checkbox"
                               @change="radio(itemIndex)"
                  />
                </el-popover>
              </div>
            </li>
            <li class="mirror" style="padding-top: 0">
              <div class="cursorPointer" style="display: flex;flex-direction: column;align-items: center"
                   @click="getEditSnInfo(item)">
                <div style="margin-bottom: 20px">
                  {{
                    updateOrderStatus === false ? item.sorting : itemIndex + 1
                  }}
                </div>
                <div v-if="item.id!=snInfo.id" style="line-height: normal">{{ item.for_sn }}</div>
                <el-input v-else v-model="item.for_sn" :placeholder="$fanyi('管理番号')" size="mini"
                          @blur="updateForSn(item)"
                          @input="$forceUpdate()"/>
              </div>
            </li>
            <li class="mirror" v-if="updateOrderStatus===false" style="flex-direction: column">
              <el-popover placement="right" trigger="hover">
                <el-image :src="item.pic"
                          style="width: 300px; height: 300px"/>
                <div slot="reference" class="flexAndCenter" style="justify-content: center;width: 88px;height: 88px;">
                  <el-image
                      @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                      :src="item.pic"
                      style="width: 60px;height: 60px;"/>
                </div>
              </el-popover>
              <el-popover placement="bottom" trigger="hover">
                <div>{{ $fanyi("手续费") }}：{{
                    $fun.returnServiceRate(item.service_rate)
                  }}%
                </div>
                <div slot="reference" :class="item.from_platform=='1688'?'goodsType':''">{{ item.from_platform }}</div>
              </el-popover>
            </li>
            <li :class="item.mirrorLeftIcon===true?'newMirror mirrorActive':'newMirror'"
                @mouseenter="mirrorMouseenter(itemIndex,item)"
                @mouseleave="mirrorMouseleave(itemIndex)" v-else>
              <div style="display: flex;justify-content: flex-end;width: 88px;margin-top:-8px"
                   v-show="item.mirrorLeftIcon===false">
                <div style="width: 26px;height: 16px;">
                  <img src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px;">
                </div>
              </div>
              <div style="display: flex;justify-content: flex-end;width: 88px;margin-top:-8px"
                   v-show="item.mirrorLeftIcon===true">
                <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center"
                     class="flexAndCenter">
                  <img src="../../../../../../assets/baiEditIcon.png" alt="" style="width:12px;height:12px">
                </div>
              </div>
              <el-upload
                  class="upload-demo"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  name="file"
                  slot="reference"
                  accept=".jpg,.jpeg,.png"
                  :before-upload="beforeAvatarUpload"
              >
                <el-popover placement="right" trigger="hover">
                  <el-image :src="item.pic"
                            @click="$fun.toCommodityDetails(item.goods_id,item.from_platform)"
                            style="width: 300px; height: 300px"
                  />
                  <el-image @click="getItemMessage(item)" :src="item.pic" slot="reference"
                            style="width: 60px;height: 60px"
                  />
                </el-popover>
              </el-upload>
              <el-popover placement="bottom" trigger="hover">
                <div>{{ $fanyi("手续费") }}：{{
                    $fun.returnServiceRate(item.service_rate)
                  }}%
                </div>
                <div slot="reference" :class="item.from_platform=='1688'?'goodsType':''">{{ item.from_platform }}</div>
              </el-popover>
            </li>
            <!-- 商品详细 -->
            <li class="goods" v-if="updateOrderStatus===false" style="width: 200px">
              <el-popover trigger="hover" placement="top">
                <div class="showDetailCon u-line">
                  <p v-for="(bitem, bindex) in item.detail"
                     :key="bitem.key + bindex"
                     :title="bitem.key + ':' + bitem.value"
                     class="goodsDetailOpt ispay"
                  >
                    {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                  </p>
                </div>
                <div class="showDetailCon" style="width:200px;padding-top: 6px"
                     slot="reference">
                  <p v-for="(bitem, bindex) in item.detail"
                     :key="bitem.key + bindex"
                     :title="bitem.key + ':' + bitem.value"
                     class="goodsDetailOpt ispay"
                     style="margin:0 20px;width:180px"
                  >
                    {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                  </p>
                </div>
              </el-popover>
            </li>
            <li style="margin:16px 3px 44px"
                @mouseenter="goodsMouseenter(itemIndex)"
                @mouseleave="goodsMouseleave(itemIndex)"
                @mousedown="goodsMouseenter(itemIndex)"
                :class="item.goodsLeftIcon===true?'newGoods mirrorActive':'newGoods'" v-else>
              <div style="display: flex;justify-content: flex-end;width: 100%" v-show="item.goodsLeftIcon===false">
                <div style="width: 26px;height: 16px;">
                  <img src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px;">
                </div>
              </div>
              <div style="display: flex;justify-content: flex-end;width: 100%" v-show="item.goodsLeftIcon===true">
                <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center"
                     class="flexAndCenter">
                  <img src="../../../../../../assets/baiEditIcon.png" alt="" style="width:12px;height:12px">
                </div>
              </div>
              <div class="showDetail" @click="getGoodsDetail(item.detail,item)">
                <el-popover trigger="hover" placement="top">
                  <div class="showDetailCon u-line">
                    <p v-for="(bitem, bindex) in item.detail"
                       :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value"
                       class="goodsDetailOpt ispay"
                    >
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                  <div slot="reference" class="showDetailCon">
                    <p v-for="(bitem, bindex) in item.detail"
                       :key="bitem.key + bindex"
                       :title="bitem.key + ':' + bitem.value"
                       class="goodsDetailOpt ispay"
                       style="margin:0 20px;width:140px"
                    >
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                  </div>
                </el-popover>
              </div>
            </li>
            <!-- 発注数 -->
            <li class="quantityOrdered goodsDetail">
              <div v-if="updateOrderStatus===false">
                {{ item.is_client_send_data.num }}
              </div>
              <el-input v-else v-model="item.is_client_send_data.num" @input="numTrunc(item)"
                        type="number" size="mini" @keydown.native="newChannelInputLimit" style="width: 60px;"/>
            </li>
            <!--                在库数-->
            <li :style="item.is_now_data.num!=item.is_client_send_data.num?'background: #B4272B;color: #fff':''"
                class="quantityOrdered goodsDetail">
              {{ item.is_now_data.num }}
            </li>
            <!-- 单价 -->
            <li class="price">
              <span class="priceText">{{ item.is_now_data.price }}</span>
            </li>
            <!-- 国内送料 -->
            <li class="theDomesticFeed">
              <span class="priceText">{{ item.is_now_data.freight }}</span>
            </li>
            <!-- 数量 -->
            <li class="theDomesticFeed" v-show="updateOrderStatus===true"
                style="display: flex;flex-direction: column;align-items: center;">
              <span class="priceText newHeight">{{ (item.totalPrice).toFixed(2) }}</span>
              <el-popover trigger="hover">
                <div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('商品金額.元') }}</div>
                    <div>{{ $fun.roundFixed(item.is_now_data.num * item.is_now_data.price) }}</div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('国内送料.元') }}</div>
                    <div>{{ item.is_now_data.num > 0 ? item.is_now_data.freight : 0 }}</div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('手数料') }}({{ $fun.returnServiceRate(item.service_rate) }}%).{{
                        $fanyi('元')
                      }}
                    </div>
                    <div>{{ $fun.roundFixed(item.service_rate_value) }}</div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('オプション手数料.元') }}</div>
                    <div>{{ $fun.roundFixed(item.optionPrice) }}</div>
                  </div>
                </div>
                <div class="quantityOrderedDetailsText" slot="reference">{{ $fanyi('详细') }}</div>
              </el-popover>
            </li>
            <li v-show="updateOrderStatus===false" class="theDomesticFeed"
                style="display: flex;flex-direction: column;align-items: center">
              <el-popover trigger="hover">
                <div>
                  <div class="flexAndCenter" style="justify-content: space-between;width:200px;margin-bottom: 10px">
                    <div>{{ $fanyi('单个成本') }}</div>
                    <div>{{
                        item.is_now_data.num == 0 ? 0 : $fun.roundFixed(item.totalPrice / item.is_now_data.num)
                      }}
                    </div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('总成本') }}</div>
                    <div>{{ $fun.roundFixed(item.totalPrice) }}</div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('国内送料.元') }}</div>
                    <div>{{ item.is_now_data.num > 0 ? item.is_now_data.freight : 0 }}</div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('手数料') }}({{ $fun.returnServiceRate(item.service_rate) }}%).{{
                        $fanyi('元')
                      }}
                    </div>
                    <div>{{ $fun.roundFixed(item.service_rate_value) }}</div>
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between;width: 100%;margin-bottom: 10px">
                    <div>{{ $fanyi('オプション手数料.元') }}</div>
                    <div>{{ $fun.roundFixed(item.optionPrice) }}</div>
                  </div>
                </div>
                <div class="quantityOrderedDetailsText" slot="reference">{{ $fanyi('总成本详细') }}</div>
              </el-popover>
            </li>
            <!-- 选项 -->
            <li class="optionSelect" v-if="updateOrderStatus===false" style="width: 180px">
              <el-popover placement="bottom" trigger="hover">
                <div>
                  <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                       style="text-align: left;">
                    <div v-if="optionItem.num>0&&optionItem.checked===true">
                      {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                        optionItem.price
                      }}{{ optionItem.unit }}/点)
                    </div>
                  </div>
                </div>
                <div slot="reference">
                  <div class="flexAndCenter" style="overflow:hidden;height: 61px;flex-direction: column;">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
                      <div v-if="optionItem.num>0&&optionItem.checked===true" style="text-align: left;width:170px"
                           class="u-line">
                        {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                          optionItem.price
                        }}{{ optionItem.unit }}/点)
                      </div>
                    </div>
                  </div>
                </div>
              </el-popover>
            </li>
            <li style="margin:16px 5px;"
                :class="item.priceLeftIcon===true?'newOptionSelect mirrorActive':'newOptionSelect'"
                @mouseenter="priceMouseenter(itemIndex)"
                @mouseleave="priceMouseleave(itemIndex)"
                @mousedown="priceMouseenter(itemIndex)" v-else>
              <div style="height: 92px">
                <div style="display: flex;justify-content: flex-end;width: 160px;"
                     v-show="item.priceLeftIcon===false">
                  <div style="width: 26px;height: 16px;">
                    <img src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px;">
                  </div>
                </div>
                <div style="display: flex;justify-content: flex-end;width: 160px" v-show="item.priceLeftIcon===true">
                  <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center;"
                       class="flexAndCenter">
                    <img src="../../../../../../assets/baiEditIcon.png" alt="" style="width:12px;height:12px">
                  </div>
                </div>
                <el-popover trigger="hover" placement="top">
                  <div style="flex-direction: column;cursor: pointer"
                       class="flexAndCenter u-line">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                         style="text-align: left;">
                      <div v-if="optionItem.num>0&&optionItem.checked===true">
                        {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                          optionItem.price
                        }}{{ optionItem.unit }}/点)
                      </div>
                    </div>
                  </div>
                  <div style="height: 75px;overflow:hidden;flex-direction: column;cursor: pointer"
                       class="flexAndCenter u-line" @click="showGoodsOption(item,item.id)"
                       slot="reference">
                    <div v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
                      <div v-if="optionItem.num>0&&optionItem.checked===true" style="text-align: left;width:150px"
                           class="u-line">
                        {{ optionIndex + 1 }}.{{ optionItem.name_translate }} *{{ optionItem.num }}({{
                          optionItem.price
                        }}{{ optionItem.unit }}/点)
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
            </li>
            <!--                标签编号-->
            <li v-if="updateOrderStatus===false" class="tagName" style="width: 150px">
              <div class="tagNameContainer">
                <div v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex">
                  <div class="tagList" v-if="tagItem.no!==''||tagItem.goods_no!==''">
                    <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                    <div class="color333 fontSize14">{{ tagItem.no }}</div>
                    <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                  </div>
                </div>
              </div>
            </li>
            <li :class="item.optionsLeftIcon===true?'tagName mirrorActive':'tagName'"
                @mouseenter="optionsMouseenter(itemIndex)"
                @mouseleave="optionsMouseleave(itemIndex)"
                v-else style="margin:16px 5px;width: 150px" @mousedown="optionsMouseenter(itemIndex)">
              <div style="height: 90px">
                <div class="updateOrderStatusTrueTagNameHoverIcon"
                     v-show="item.optionsLeftIcon===false">
                  <div style="width: 26px;height: 16px;">
                    <img src="../../../../../../assets/redEditIcon.png" style="width:12px;height:12px;">
                  </div>
                </div>
                <div style="display: flex;justify-content: flex-end;width: 150px;margin-top: 2px"
                     v-show="item.optionsLeftIcon===true">
                  <div style="width: 26px;height: 16px;background: #B4272B;justify-content: center;"
                       class="flexAndCenter">
                    <img src="../../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
                  </div>
                </div>
                <div @click="getGoodsTag(item)" class="updateOrderStatusTrueOrder_detail_tag">
                  <div v-for="(tagItem,tagIndex) in item.order_detail_tag" :key="tagIndex">
                    <div class="tagList">
                      <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                      <div class="color333 fontSize14">{{ tagItem.no }}</div>
                      <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 备注 -->
            <li class="remark">
              <el-popover v-show="updateOrderStatus===false" trigger="hover">
                <div style="width: 200px">{{ item.client_remark }}</div>
                <div class="clientRemark" slot="reference">{{ item.client_remark }}</div>
              </el-popover>
              <div v-show="updateOrderStatus===true">
                <textarea v-model="item.client_remark" resize="none"></textarea>
              </div>
            </li>
            <!--业务回复-->
            <li class="worker" :style="updateOrderStatus===true?'width:140px':'width:100px'">
              <el-popover trigger="hover">
                <div style="width: 200px">{{ item.y_reply }}</div>
                <div class="u-line-5" slot="reference" :style="updateOrderStatus===true?'width:140px':'width:100px'">
                  {{ item.y_reply }}
                </div>
              </el-popover>
            </li>
          </ul>
        </transition-group>
        <SpecialNoteVue ref="SpecialNote" :goodsTotalServiceChargeDetailsList="goodsTotalServiceChargeDetailsList"
                        :goodsTotalPrice="goodsTotalPrice"
                        :goodsTotalServiceCharge="goodsTotalServiceCharge"
                        :optionTotalPrice="optionTotalPrice" :RMBTotalPrice="RMBTotalPrice"
                        :yenTotalPrice="yenTotalPrice" :orderStatus="orderStatus"
                        :selectOptionList="selectOptionList" :freightTotalPrice="freightTotalPrice"
                        :otherPrice="Number(datas.other_price)" :obligationEditUserRemark="updateOrderStatus"
                        :otherPriceRemark="datas.other_price_remark" :businessRemark="datas.y_reply"/>
      </div>
    </div>
    <inBatchesPaymentDetails v-if="datas.order_part_pay.length>0&&updateOrderStatus===false"/>
    <div v-if="updateOrderStatus===false&&datas.order_part_pay.length===0" class="operatingOptions">
      <div style="margin-left: 25px">
        <div class="flexAndCenter" style="margin-right: 20px">
          <input v-model="checked" type="checkbox" @change="allChoice"/>
          <button class="qx" @click="allChoice()">
            {{ $fanyi("全选") }}
          </button>
        </div>
        <div class="goodsNumContainer flexAndCenter color333">
          {{ $fanyi('商品数') }}
          <div>{{ goodNum }}</div>
          {{ $fanyi('件') }}
        </div>
        <div class="flexAndCenter color333">
          （1{{ $fanyi('元') }}={{ exchangeRate }}{{ $fanyi('円') }}）
        </div>
        <div class="AdditionalBtn" @click="goodPushInventory">商品庫に入る</div>
        <div v-if="datas.amazon_authorization==true" class="AdditionalBtn" style="width: 120px"
             @click="markerAmazon">
          亚马逊入仓商品
        </div>
        <div v-if="couponList.length>0&&datas.status!==40" class="couponCheckedContainer flexAndCenter">
          <el-popover
              placement="top"
              width="420"
              popper-class="couponCheckedPopoverContainer"
              trigger="hover">
            <div class="couponMessageContainer">
              <div class="couponListContainer">
                <div v-for="(item,index) in couponList" :key="index" class="couponContainer"
                     @click="confirmCheckedCoupon(index,item.id)">
                  <div>
                    <div class="couponName">
                      <div>{{ item.name_translation }}</div>
                      <div>注文書</div>
                    </div>
                    <div class="couponNo">NO.{{ item.sn }}</div>
                    <div class="date">有効期限&nbsp;&nbsp;&nbsp;{{ item.effective_date.substring(0, 10) }} - {{
                        item.expiry_date.substring(0, 10)
                      }}
                    </div>
                  </div>
                  <div class="couponType">
                    <div>ク</div>
                    <div>ー</div>
                    <div>ポ</div>
                    <div>ン</div>
                    <div>券</div>
                  </div>
                  <div v-show="checkedCouponIndex===index" class="checkedCouponContainer">√</div>
                </div>
              </div>
            </div>
            <el-checkbox
                v-model="couponChecked"
                @change="couponCheckedChange"
                slot="reference">{{ $fanyi("使用优惠券") }}
            </el-checkbox>
          </el-popover>
        </div>
      </div>
      <div class="buttonGroup" v-if="datas.status!==40">
        <button @click="updateOrderStatus=true;updateOrderGoodsServiceRate()">
          {{ $fanyi('注文書修正') }}
        </button>
        <button @click="paymentPageJump">
          {{ $fanyi('支払い') }}
        </button>
      </div>
    </div>
    <div v-else-if="updateOrderStatus===false&&datas.order_part_pay.length>0" class="operatingOptions"
         style="height: 120px">
      <div class="flexAndCenter">
        <input v-model="checked" type="checkbox" @change="allChoice"/>
        <button class="qx" @click="allChoice()">
          {{ $fanyi("全选") }}
        </button>
      </div>
      <div style="margin-left: 25px">
        <div class="AdditionalBtn" @click="goodPushInventory">商品庫に入る</div>
      </div>
      <div v-if="datas.amazon_authorization==true" class="AdditionalBtn" style="width: 120px"
           @click="markerAmazon">
        亚马逊入仓商品
      </div>
      <div style="flex-direction: column;justify-content: center">
        <div class="goodsNumContainer flexAndCenter color333">
          {{ $fanyi('商品数') }}
          <div>{{ goodNum }}</div>
          {{ $fanyi('件') }}
        </div>
        <div class="goodsNumContainer flexAndCenter color333" style="margin-top: 15px">
          {{ $fanyi('需要支付金额') }}：
          <div>{{
              JSON.stringify(orderPayPriceInfo) != '{}' ? $fun.yenNumSegmentation(orderPayPriceInfo.part_pay_amount.JPY.pay_amount_jpy) : datas.part_pay_status == 1 ? $fun.yenNumSegmentation(datas.order_part_pay[0].yenTotal) : $fun.yenNumSegmentation(datas.order_part_pay[1].yenTotal)
            }}
          </div>
          {{ $fanyi('円') }}（1{{
            $fanyi('元')
          }}={{
            datas.part_pay_status_name != undefined && datas.part_pay_status_name == '待付尾款' ? datas.exchange_rate : exchangeRate
          }}{{ $fanyi('円') }}）
        </div>
      </div>
      <div style="flex-direction: column;justify-content: center;align-items: normal">
        <div :class="datas.part_pay_status==1?'downPaymentContainer':'goodsNumContainer'"
             :style="datas.part_pay_status==1?'':'margin-left:16px'"
             class="flexAndCenter  color333"
             style="margin-bottom: 11px">{{ $fanyi('首次付款') }}：
          <div>{{ datas.order_part_pay[0].rmbTotal.toFixed(2) }}</div>
          元（{{ $fun.yenNumSegmentation(datas.order_part_pay[0].yenTotal) }}円）
        </div>
        <div :class="datas.part_pay_status>1?'downPaymentContainer':'goodsNumContainer'"
             :style="datas.part_pay_status==1?'margin-left: 16px':''"
             class="flexAndCenter  color333">
          {{ $fanyi('尾款金额') }}：
          <div>{{ datas.order_part_pay[1].rmbTotal.toFixed(2) }}</div>
          元（{{ $fun.yenNumSegmentation(datas.order_part_pay[1].yenTotal) }}円）
        </div>
      </div>
      <div
          v-if="couponList.length>0&&datas.order_amount.discounts_detail.length===0&&((datas.part_pay_status==1&&Number(new_order_part_pay[0].service_amount)>0)||(datas.part_pay_status==3&&Number(new_order_part_pay[1].service_amount)>0))"
          class="couponCheckedContainer flexAndCenter">
        <el-popover
            placement="top"
            popper-class="couponCheckedPopoverContainer"
            trigger="hover"
            width="420">
          <div class="couponMessageContainer">
            <div class="couponListContainer">
              <div v-for="(item,index) in couponList" :key="index" class="couponContainer"
                   @click="confirmCheckedCoupon(index,item.id)">
                <div>
                  <div class="couponName">
                    <div>{{ item.name_translation }}</div>
                    <div>注文書</div>
                  </div>
                  <div class="couponNo">NO.{{ item.sn }}</div>
                  <div class="date">有効期限&nbsp;&nbsp;&nbsp;{{ item.effective_date.substring(0, 10) }} - {{
                      item.expiry_date.substring(0, 10)
                    }}
                  </div>
                </div>
                <div class="couponType">
                  <div>ク</div>
                  <div>ー</div>
                  <div>ポ</div>
                  <div>ン</div>
                  <div>券</div>
                </div>
                <div v-show="checkedCouponIndex===index" class="checkedCouponContainer">√</div>
              </div>
            </div>
          </div>
          <el-checkbox
              slot="reference"
              v-model="couponChecked"
              @change="couponCheckedChange">{{ $fanyi("使用优惠券") }}
          </el-checkbox>
        </el-popover>
      </div>
      <div v-if="datas.status!==40" class="buttonGroup">
        <button v-if="datas.part_pay_status==1" @click="updateOrderStatus=true;updateOrderGoodsServiceRate()">
          {{ $fanyi('注文書修正') }}
        </button>
        <button v-if="datas.part_pay_status==1||datas.part_pay_status==3"
                :style="datas.part_pay_status>1?'background: #B4272B;color:#fff':''"
                @click="paymentPageJump">
          {{ datas.part_pay_status == 1 ? $fanyi('支付定金') : $fanyi('支付尾款') }}
        </button>
      </div>
    </div>
    <div class="operatingOptions" v-else>
      <div class="choies">
        <input type="checkbox" v-model="checked" @change="allChoice"/>
        <button class="qx" @click="allChoice()">
          {{ $fanyi("全选") }}
        </button>
        <div class="deleteText" @click="delOrder">{{ $fanyi("删除") }}</div>
        <div class="AdditionalBtn" @click="goodPushInventory">商品庫に入る</div>
        <div v-if="datas.amazon_authorization==true" class="AdditionalBtn" style="width: 120px"
             @click="markerAmazon">
          亚马逊入仓商品
        </div>
        <el-popover placement="top-start" width="358" trigger="hover">
          <p class="caoZuoAnNiu">
            <button class="addgoods" @click="showAddCartGoodsToOrderDialog(1)">
              {{ $fanyi("从购物车中导入更多产品") }}
            </button>
          </p>
          <p class="caoZuoAnNiu">
            <button class="addLibraryOfGoods" @click="showAddCartGoodsToOrderDialog(2)">
              {{ $fanyi("从商品库中导入更多产品") }}
            </button>
          </p>
          <div class="AdditionalBtn" slot="reference">{{ $fanyi("追加") }}</div>
        </el-popover>
        <div class="goodsNum flexAndCenter">{{ $fanyi("商品数") }}
          <div>{{ Number(goodNum) }}</div>
          {{ $fanyi("件") }}
        </div>
      </div>
      <div class="buttonGroup">
        <button @click="goPaymentStatus" style="width: 130px">
          {{ $fanyi("返回付款") }}
        </button>
        <button @click="updateOrderMessageAndStatus">{{ $fanyi("再提出") }}</button>
      </div>
    </div>
    <div class="goodsOptionListContainer">
      <el-dialog
          :title="$fanyi('オプション')"
          :visible.sync="showGoodsOptionDialogVisible"
          width="700px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center>
        <saveOption :list="showGoodsOptionList" :key="timer" :orderList="newList" :itemIndex="itemIndex"
                    :option="optionList" :orderStatus="orderStatus"
                    @saveGoodsOption="saveGoodsOption" :optionPrice="optionPrice"/>

      </el-dialog>
      <newOption :key="timer" :itemIndex="itemIndex" :list="showGoodsOptionList" :option="optionList"
                 :optionPrice="optionPrice" :orderList="newList"
                 :orderStatus="orderStatus" @saveGoodsOption="saveGoodsOption"/>
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="selectAlternativeLinkDialogVisible"
          width="931px">
        <selectAlternativeLink :goodsInfo="goodsInfo" :key="timer"
                               @confirmReplaceOrderGoods="confirmReplaceOrderGoods"/>
      </el-dialog>
    </div>
    <div class="goodsAttributeEditContainer">
      <el-dialog
          :title="$fanyi('属性编辑')"
          :visible.sync="goodsDetailDialogVisible"
          width="516px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center>
        <goodsAttributeEdit :goodsDetailList="goodsDetailList" :key="timer" @saveGoodsDetail="saveGoodsDetail"
                            @hideGoodsDetailDialogVisible="hideGoodsDetailDialogVisible"
                            ref="goodsAttributeEditDialog"/>
      </el-dialog>
    </div>
    <div class="addCartGoodsToOrderContainer">
      <el-dialog
          :title="$fanyi('カードから追加')"
          :visible.sync="addCartGoodsToOrderDialogVisible"
          width="1408px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center>
        <cart @cartAddGoodsToOrder="cartAddGoodsToOrder" :key="addTimer" :ids="ids"/>
      </el-dialog>
      <div class="holdOrderDialogVisibleContainer">
        <el-dialog
            :visible.sync="selectTagDialogVisible"
            width="1030px"
            :title="$fanyi('标签设置')"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center>
          <editTag @saveGoodsTag="saveGoodsTag" :key="timer" :list="order_detail_tag"
                   ref="editTag" :fullscreenLoading="newFullscreenLoading" :goodsInfo="goodsInfo"
                   :isShow="datas.amazon_authorization"/>
        </el-dialog>
        <el-dialog
            :title="$fanyi('商品庫から追加')"
            :visible.sync="addInventoryGoodsToOrderDialogVisible"
            width="1462px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            center>
          <inventory @inventoryAddGoodsToOrder="inventoryAddGoodsToOrder" :key="addTimer" :ids="ids"/>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import SpecialNoteVue from '../newCom/SpecialNote'
import cart from '../components/cart'
import inventory from '../components/inventory'
import editTag from './editTag'
import saveOption from './saveOption'
import newOption from './newOption.vue'
import selectAlternativeLink from './selectAlternativeLink'
import goodsAttributeEdit from './goodsAttributeEdit'
import inBatchesPaymentDetails from "@/views/UserCenter/views/userOrder/views/components/inBatchesPaymentDetails.vue";
import statistics from "@/views/UserCenter/views/userOrder/views/components/statistics.vue";

export default {
  components: {
    statistics,
    SpecialNoteVue,
    cart,
    inventory,
    editTag,
    saveOption,
    newOption,
    selectAlternativeLink,
    goodsAttributeEdit,
    inBatchesPaymentDetails
  },
  props: {
    orderStatusName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      checked: false,
      goodNum: 0,
      ending: null,
      dragging: null,
      freightTotalPrice: 0,
      selectTagDialogVisible: false,
      newFullscreenLoading: true,
      discounts_id: '',
      selectAlternativeLinkDialogVisible: false,
      addCartGoodsToOrderDialogVisible: false,
      addInventoryGoodsToOrderDialogVisible: false,
      ids: '',
      timer: null,
      obligationStatus: true,
      order_detail_tag: [],
      checkedList: [],
      cartChecked: false,
      goodsTotalServiceChargeDetailsList: [],
      goodsTotalPrice: 0,
      attributeTypeStatus: 1,
      goodsDetailDialogVisible: false,
      showGoodsOptionDialogVisible: false,
      newShowGoodsOptionDialogVisible: false,
      checkedCouponIndex: null,
      orderStatus: 'obligation',
      addTimer: null,
      goodsInfo: {},
      itemIndex: 0,
      goodsDetailList: [],
      showGoodsOptionList: [],
      goodsTotalServiceCharge: 0,
      optionTotalPrice: 0,
      RMBTotalPrice: 0,
      optionPrice: 0,
      yenTotalPrice: 0,
      selectOptionList: [],
      deepCloneNewList: [],
      orderPayPriceInfo: {},
      couponList: [],
      service_rate_list: [],
      new_order_part_pay: [],
      couponChecked: false,
      couponCheckedStatus: false,
      updateOrderStatus: false,
      fullscreenLoading: true,
      datas: {
        other_price: 0,
        order_amount: {
          predict_logistics_price_rmb: 0
        },
        order_part_pay: []
      },
      snInfo: {},
      input: '',
      newList: [],
      optionList: []
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    this.$api.optionList({
      need_desc: true
    }).then((res) => {
      res.data.forEach((item) => {
        item.total = 0;
      })
      this.optionList = res.data;
      this.getData(this.$route.query.order_sn);
    });
    this.$store.commit("updateClient_remark", {text: ''});
    this.$store.commit("updateFollow_remark", {text: ''});
    this.$store.commit("emptyFileList", {length: 0});
    this.$api.orderUsableDiscount({
      page: 1, pageSize: 999,
      order_sn: this.$route.query.order_sn
    }).then((res) => {
      if (this.$fun.isArray(res.data) === false) {
        this.couponList = res.data.data;
      }
    });
  },
  methods: {
    // 通过订单号获取数据
    getData(order_sn) {
      let logisticsArr = [];
      this.$api.internationalLogisticsTransportation().then((res) => {
        res.data.forEach((item) => {
          if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
            item.name += '―定額会員限定ルート'
          }
        })
        this.$store.commit("getLogisticsTransportation", {logisticsTransportation: res.data});
        logisticsArr = res.data;
        this.$api.orderDetail({
          order_sn: order_sn,
        }).then((res) => {
          if (res.data.amazon_authorization == undefined) {
            res.data.amazon_authorization = false;
          }
          this.fullscreenLoading = false;
          res.data.other_price = Number(res.data.other_price);
          if (res.data.status < 30) {
            this.getOrderPayPriceInfo();
          }
          if (res.data.order_part_pay.length > 0) {
            let exchangeRate = res.data.part_pay_status_name != undefined && res.data.part_pay_status_name == '待付尾款' ? res.data.exchange_rate : this.exchangeRate;
            res.data.order_part_pay.forEach((item) => {
              if (res.data.order_amount.discounts_detail.length === 0) {
                item.rmbTotal = Number(item.goods_amount) + Number(item.service_amount) + Number(item.option_amount) + Number(item.freight_amount) + Number(item.other_amount);
              } else {
                item.rmbTotal = Number(item.goods_amount) + Number(item.option_amount) + Number(item.freight_amount) + Number(item.other_amount);
              }
              item.yenTotal = 0;
              item.yenTotal += Number((Number(item.goods_amount) * Number(exchangeRate)).toFixed(0));
              item.yenTotal += res.data.order_amount.discounts_detail.length === 0 ? Number((Number(item.service_amount) * Number(exchangeRate)).toFixed(0)) : 0
              item.yenTotal += Number((Number(item.option_amount) * Number(exchangeRate)).toFixed(0))
              item.yenTotal += Number((Number(item.freight_amount) * Number(exchangeRate)).toFixed(0))
              item.yenTotal += Number((Number(item.other_amount) * Number(exchangeRate)).toFixed(0))
            })
            this.new_order_part_pay = this.$fun.deepClone(res.data.order_part_pay);
          }
          this.datas = res.data;
          if (res.data.logistics != undefined) {
            for (let i = 0; i < logisticsArr.length; i++) {
              if (res.data.logistics.id == logisticsArr[i].id) {
                this.$refs.SpecialNote.selectValue = res.data.logistics.id
                this.$refs.SpecialNote.logisticsTransportationTips = logisticsArr[i].tips
                break;
              } else {
                this.$refs.SpecialNote.selectValue = ''
              }
            }
          } else {
            this.$refs.SpecialNote.selectValue = ''
          }
          this.orderPriceGroup = res.data.order_money_group;
          if (res.data.order_file.length > 0) {
            res.data.order_file.forEach((item) => {
              this.$refs.SpecialNote.fileList.push({
                name: this.$fun.deepClone(item.name),
                file: this.$fun.deepClone(item.file)
              })
            })
          }
          this.$store.commit("updateClient_remark", {text: res.data.client_remark});
          this.$store.commit("updateFollow_remark", {text: res.data.follow_remark});
          let arr = [];
          res.data.order_detail.forEach((item) => {
            item.goods.forEach((goodsItems) => {
              goodsItems.mirrorLeftIcon = false;
              goodsItems.goodsLeftIcon = false;
              goodsItems.priceLeftIcon = false;
              goodsItems.optionsLeftIcon = false;
              goodsItems.optionPrice = 0;
              goodsItems.optionCheckedNum = 0;
              goodsItems.getOptionStatus = false;
              if (goodsItems.option != null) {
                goodsItems.optionChecked = true
                goodsItems.option.forEach((optionItem) => {
                  if ((Number(optionItem.num) > 0 && optionItem.checked === true) || (optionItem.price_type === 1 && optionItem.checked === true)) {
                    if (optionItem.price_type === 1) {
                      optionItem.total = (optionItem.num * Number(goodsItems.is_now_data.price)) * (Number(optionItem.price) / 100);
                      goodsItems.optionPrice += (optionItem.num * Number(goodsItems.is_now_data.price)) * (Number(optionItem.price) / 100);
                      goodsItems.optionCheckedNum++
                    }
                    if (optionItem.price_type === 0) {
                      optionItem.total = Number(optionItem.num) * Number(optionItem.price);
                      goodsItems.optionPrice += Number(optionItem.num) * Number(optionItem.price);
                      goodsItems.optionCheckedNum++
                    }
                  }
                })
              } else {
                goodsItems.option = [];
                goodsItems.optionChecked = false
              }
              this.freightTotalPrice += Number(goodsItems.is_now_data.freight)
              this.service_rate_list.push(this.$fun.returnServiceRate(goodsItems.service_rate));
              goodsItems.service_rate_value = (goodsItems.is_now_data.num * Number(goodsItems.is_now_data.price)) * Number(goodsItems.service_rate);
              goodsItems.totalPrice = goodsItems.optionPrice + (goodsItems.is_now_data.num * Number(goodsItems.is_now_data.price)) + Number(goodsItems.is_now_data.freight) + goodsItems.service_rate_value;
              arr.push(goodsItems);
            })
          })
          this.newList = arr;
          this.deepCloneNewList = this.$fun.deepClone(arr);
          this.commodityTotal();
        });
      });
    },
    handleDragStart(e, item) {
      this.dragging = item
    },
    handleDragEnd(e, item) {
      this.dragging = null
    },
    handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move'
    },
    handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = 'move'
      if (item === this.dragging) {
        return
      }
      const newItems = [...this.newList]
      const src = newItems.indexOf(this.dragging)
      const dst = newItems.indexOf(item)
      newItems.splice(dst, 0, ...newItems.splice(src, 1))
      this.newList = newItems
    },
    updateForSn(val) {
      if (val.for_sn != '') {
        this.$api.updateOrderDetailForSn({order_detail_id: val.id, for_sn: val.for_sn}).then((res) => {

        });
      }
    },
    markerAmazon() {
      let count = 0;
      let arr = [];
      this.newList.forEach((item) => {
        if (item.checked == true) {
          count++
          item.warehousing = !item.warehousing
          this.$forceUpdate();
          arr.push(item.id)
        }
      })
      if (count == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'))
        return
      }
      this.$api.markersWarehouseGoods({
        order_sn: this.$route.query.order_sn,
        order_detail_id: arr
      }).then((res) => {
      })
    },
    //获取要编辑的管理番号详情
    getEditSnInfo(val) {
      this.snInfo = val;
    },
    //获取订单的金额
    getOrderPayPriceInfo() {
      this.$api.orderPayment({
        order_sn: this.$route.query.order_sn,
        discounts_id: this.discounts_id
      }).then((res) => {
        this.orderPayPriceInfo = res.data;
        if (this.discounts_id !== '') {
          this.yenTotalPrice = this.orderPayPriceInfo.pay_amount_jpy;
          this.RMBTotalPrice = Number(this.orderPayPriceInfo.pay_amount);
        }
        if (this.datas.order_part_pay.length > 0) {
          let exchangeRate = this.datas.part_pay_status_name != undefined && this.datas.part_pay_status_name == '待付尾款' ? res.data.exchange_rate : this.exchangeRate;
          this.datas.order_part_pay = this.$fun.deepClone(this.new_order_part_pay);
          this.datas.order_part_pay.forEach((item) => {
            if (this.discounts_id == '') {
              item.rmbTotal = Number(item.goods_amount) + Number(item.service_amount) + Number(item.option_amount) + Number(item.freight_amount) + Number(item.other_amount);
            } else {
              item.service_amount = 0;
              item.rmbTotal = Number(item.goods_amount) + Number(item.option_amount) + Number(item.freight_amount) + Number(item.other_amount);
            }
            item.yenTotal = 0;
            item.yenTotal += Number((Number(item.goods_amount) * Number(exchangeRate)).toFixed(0));
            item.yenTotal += this.discounts_id !== '' ? 0 : Number((Number(item.service_amount) * Number(exchangeRate)).toFixed(0))
            item.yenTotal += Number((Number(item.option_amount) * Number(exchangeRate)).toFixed(0))
            item.yenTotal += Number((Number(item.freight_amount) * Number(exchangeRate)).toFixed(0))
            item.yenTotal += Number((Number(item.other_amount) * Number(exchangeRate)).toFixed(0))
          })
        }
      })
    },
    //保存商品的附加服务
    saveGoodsOption(msg) {
      let count = 0;
      this.optionTotalPrice = 0;
      this.newList.forEach((item) => {
        count = 0;
        item.optionPrice = 0;
        if (item.checked === true) {
          msg.forEach((msgItem) => {
            if (msgItem.checked === true && msgItem.num > 0) {
              item.optionCheckedNum++;
            }
          })
          item.option = this.$fun.deepClone(msg);
        } else if (this.itemIndex === item.id) {
          msg.forEach((msgItem) => {
            if (msgItem.checked === true && msgItem.num > 0) {
              item.optionCheckedNum++;
            }
          })
          item.option = this.$fun.deepClone(msg);
        }
        item.option.forEach((optionItem) => {
          if (optionItem.checked === true && Number(optionItem.num) > 0 && optionItem.price_type === 0) {
            if (item.checked === true) {
              if (optionItem.num > item.is_client_send_data.num) {
                optionItem.num = item.is_client_send_data.num
              }
            }
            item.optionPrice += optionItem.num * optionItem.price;
            this.optionTotalPrice += optionItem.num * optionItem.price;
            optionItem.total = optionItem.num * optionItem.price;
            count++
          }
          if (optionItem.checked === true && optionItem.price_type === 1) {
            if (item.checked === true) {
              if (optionItem.num > item.is_client_send_data.num) {
                optionItem.num = item.is_client_send_data.num
              }
            }
            item.optionPrice += (optionItem.num * Number(item.is_now_data.price)) * (Number(optionItem.price) / 100);
            this.optionTotalPrice += (optionItem.num * Number(item.is_now_data.price)) * (Number(optionItem.price) / 100);
            optionItem.total = (optionItem.num * Number(item.is_now_data.price)) * (Number(optionItem.price) / 100);
            count++
          }
        })
        count > 0 ? item.optionChecked = true : item.optionChecked = false
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.showGoodsOptionDialogVisible = false;
      this.commodityTotal();
    },
    //保存商品的标签编号
    saveGoodsTag(msg) {
      this.newList.forEach((item) => {
        if (item.id === this.goodsInfo.id) {
          let arr = [];
          msg.forEach((item) => {
            if (item.no !== '' || item.goods_no !== '') {
              for (let i = 0; i < item.list.length; i++) {
                if (item.list[i].value === item.type) {
                  item.type_translate = item.list[i].label;
                  break;
                }
              }
              arr.push(item)
            }
          })
          item.order_detail_tag = arr;
        }
      })
      this.order_detail_tag = [];
      this.selectTagDialogVisible = false;
    },
    //写真鼠标移入
    mirrorMouseenter(index, item) {
      this.newList[index].mirrorLeftIcon = true;
      this.goodsInfo = item;
      this.$forceUpdate();
    },
    //写真鼠标移出
    mirrorMouseleave(index) {
      this.newList[index].mirrorLeftIcon = false;
      this.$forceUpdate();
    },
    //商品详细鼠标移入
    goodsMouseenter(index) {
      this.newList[index].goodsLeftIcon = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(index) {
      this.newList[index].goodsLeftIcon = false;
      this.$forceUpdate();
    },
    //option详细鼠标移入
    priceMouseenter(index) {
      this.newList[index].priceLeftIcon = true;
      this.$forceUpdate();
    },
    //option详细鼠标移出
    priceMouseleave(index) {
      this.newList[index].priceLeftIcon = false;
      this.$forceUpdate();
    },
    //标签编号鼠标移入
    optionsMouseenter(index) {
      this.newList[index].optionsLeftIcon = true;
      this.$forceUpdate();
    },
    //标签编号鼠标移出
    optionsMouseleave(index) {
      this.newList[index].optionsLeftIcon = false;
      this.$forceUpdate();
    },
    //商品链接转商品ID，在订单中搜索该id
    linkSearch() {
      if (this.input !== '') {
        let arr = [];
        this.$api.goodsUrlToGoodsId({goods_url: this.input}).then((res) => {
          this.newList.forEach((item) => {
            if (item.goods_id === res.data.goods_id) {
              arr.push(item)
            }
          })
          if (arr.length === 0) {
            this.$message.error(this.$fanyi("订单中未找到该商品"))
          } else {
            this.newList = arr;
          }
        });
      }
    },
    //返回待支付状态
    goPaymentStatus() {
      this.updateOrderStatus = false;
      this.getData(this.$route.query.order_sn);
      this.selectOptionList.length = 0;
      this.$store.commit("emptyFileList", {length: 0});
      this.$refs.SpecialNote.fileList = [];
    },
    //重置商品链接搜索
    resetLinkSearch() {
      this.input = '';
      this.newList = this.$fun.deepClone(this.deepCloneNewList);
    },
    //更新使用优惠券的状态
    couponCheckedChange() {
      if (this.couponChecked === false && this.discounts_id !== '') {
        this.discounts_id = '';
        this.checkedCouponIndex = null;
        this.getOrderPayPriceInfo();
        this.getData(this.$route.query.order_sn);
      }
      if (this.discounts_id === '' && this.couponChecked === true) {
        this.couponChecked = false;
        this.$forceUpdate();
      }
    },
    //选中优惠券
    confirmCheckedCoupon(index, id) {
      if (this.checkedCouponIndex !== index) {
        this.checkedCouponIndex = index;
        this.discounts_id = id;
        this.couponChecked = true;
        this.newList.forEach((item) => {
          item.service_rate = 0;
        })
      } else if (this.checkedCouponIndex === index) {
        this.checkedCouponIndex = null;
        this.discounts_id = '';
        this.couponChecked = false;
      }
      this.commodityTotal();
      this.getOrderPayPriceInfo();
    },
    //更新订单商品单番手续费
    updateOrderGoodsServiceRate() {
      if (this.discounts_id !== '' && this.couponChecked === true) {
        this.newList.forEach((item, index) => {
          item.service_rate = this.service_rate_list[index];
          if (index === this.newList.length - 1) {
            this.commodityTotal();
          }
        })
      } else {
        this.commodityTotal();
      }
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //付款前判断目前订单中是否有在库0的
    paymentPageJump() {
      let str = '';
      let count = 0;
      let arr = [];
      this.newList.forEach((item, index) => {
        if (item.is_now_data.num == 0 && item.status != -2) {
          count++;
          str += item.sorting + '、'
        }
        if (item.is_now_data.num != 0 && item.status != -2) {
          arr.push(item)
        }
      })
      str = str.substr(0, str.length - 1);
      str = str + '番';
      if (count === 0) {
        localStorage.setItem('paySubmitGoodsList', JSON.stringify(arr));
        this.$fun.routerToPage(`/payment?order_sn=${this.$route.query.order_sn}&discounts_id=${this.discounts_id}`)
      } else if (count !== this.newList.length) {
        this.$confirm(
            `現在の注文書：${str}の在庫が0です。確認後、上記の商品は購入がキャンセルされます。`,
            '注意',
            {
              confirmButtonText: this.$fanyi('确定'), //确定
              cancelButtonText: this.$fanyi('取消'), //取消
              type: "warning",
              center: true,
              dangerouslyUseHTMLString: true,
            }
        ).then(() => {
          this.$api.updateOrderStatus({
            order_sn: this.$route.query.order_sn,
          }).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi(res.msg));
            localStorage.setItem('paySubmitGoodsList', JSON.stringify(arr));
            setTimeout(() => {
              this.$fun.routerToPage(`/payment?order_sn=${this.$route.query.order_sn}&discounts_id=${this.discounts_id}`);
            }, 1000)
          });
        })
      } else if (count === this.newList.length) {
        this.$confirm(
            `ご注文の商品すべて在庫不足のため、こちらの注文書はキャンセル状態になります。`,
            '注意',
            {
              confirmButtonText: this.$fanyi('确定'), //确定
              cancelButtonText: this.$fanyi('取消'), //取消
              type: "warning",
              center: true,
              dangerouslyUseHTMLString: true,
            }
        ).then(() => {
          this.$api.updateOrderStatus({
            order_sn: this.$route.query.order_sn,
          }).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi(res.msg));
            setTimeout(() => {
              window.close();
            }, 1000)
          });
        })
      }
    },
    //关闭商品属性编辑弹窗
    hideGoodsDetailDialogVisible() {
      this.goodsDetailDialogVisible = false;
    },
    //输入框取整
    numTrunc(item) {
      item.is_client_send_data.num = this.$fun.priceReplace(item.is_client_send_data.num);
      this.commodityTotal();
    },
    //确认替换商品
    confirmReplaceOrderGoods(id) {
      this.$api.replaceGoods({
        replace_id: id,
      }).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.selectAlternativeLinkDialogVisible = false;
        this.getData(this.$route.query.order_sn);
      });
    },
    //获取商品tag
    getGoodsTag(item) {
      this.goodsInfo = item;
      this.selectTagDialogVisible = true;
      this.$api.getOrderTagList({
        order_detail_id: item.id
      }).then((res) => {
        let list = [];
        res.data.forEach((item) => {
          list.push({
            value: item.type,
            label: item.type_translate
          })
        })
        if (item.order_detail_tag.length === 0) {
          let order_detail_tag = [];
          order_detail_tag.push({
            no: '',
            goods_no: '',
            list: list,
            type: 'FBA'
          })
          setTimeout(() => {
            this.newFullscreenLoading = false;
            this.order_detail_tag = this.$fun.deepClone(order_detail_tag);
            this.$refs.editTag.value = order_detail_tag[0].id
          }, 500)
        } else {
          item.order_detail_tag.forEach((items) => {
            items.list = list
          })
          this.$refs.editTag.value = item.order_detail_tag[0].id;
          this.order_detail_tag = this.$fun.deepClone(item.order_detail_tag);
          setTimeout(() => {
            this.newFullscreenLoading = false;
          }, 500)
        }
      });
    },
    //追加商品购物车的商品至订单
    cartAddGoodsToOrder(msg) {
      let count = this.newList.length;
      msg.forEach((item) => {
        count++;
        item.is_now_data = {
          num: item.num,
          freight: 0,
          price: Number(item.price)
        };
        item.is_client_send_data = {
          num: item.num
        }
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.specification.length > 0) {
            item.goodsInfo.specification.forEach((specificationItem) => {
              specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                item.detail.forEach((detailItem) => {
                  if (detailItem.key === specificationItem.keyC && detailItem.value === valueCItem.name) {
                    specificationItem.active = valueCIndex
                  }
                })
              })
            })
          }
        }
        item.cart_id = item.id;
        item.totalPrice = (Number(item.num) * Number(item.price));
        item.pushStatus = true;
        item.mirrorLeftIcon = false;
        item.goodsLeftIcon = false;
        item.priceLeftIcon = false;
        item.optionsLeftIcon = false;
        item.order_detail_tag = [];
        item.checked = false;
        item.for_sn = '';
        item.optionChecked = false;
        item.option = JSON.parse(JSON.stringify(this.optionList));
        item.optionPrice = 0;
        item.sorting = count;
        item.order_detail_replace = [];
        this.newList.push(item)
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.addCartGoodsToOrderDialogVisible = false;
      this.commodityTotal();
    },
    //追加商品库商品的商品至订单
    inventoryAddGoodsToOrder(msg) {
      let count = this.newList.length;
      msg.forEach((item) => {
        count++;
        item.checked = false;
        if (typeof item.detail === 'string') {
          item.detail = JSON.parse(item.detail);
        }
        item.is_now_data = {
          num: 1,
          freight: 0,
          price: Number(item.price)
        };
        item.is_client_send_data = {
          num: item.num
        }
        item.mirrorLeftIcon = false;
        item.goodsLeftIcon = false;
        item.priceLeftIcon = false;
        item.optionsLeftIcon = false;
        item.getOptionStatus = true;
        item.pushStatus = true;
        item.orderTagList = item.inventory_tag;
        item.pic = item.img_url;
        if (item.goodsInfo != undefined) {
          if (item.goodsInfo.specification.length > 0) {
            item.goodsInfo.specification.forEach((specificationItem) => {
              specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                item.detail.forEach((detailItem) => {
                  if (detailItem.key === specificationItem.keyC && detailItem.value === valueCItem.name) {
                    specificationItem.active = valueCIndex
                  }
                })
              })
            })
          }
        }
        item.sorting = count;
        item.totalPrice = 0;
        item.option.length === 0 ? item.optionChecked = false : item.optionChecked = true;
        if (item.option.length !== this.optionList.length) {
          let newOptionList = this.$fun.deepClone(this.optionList);
          item.option.forEach((item1) => {
            newOptionList.forEach((optionItem) => {
              if (item1.id === optionItem.id) {
                optionItem.num = item1.num;
                optionItem.checked = item1.checked;
              }
            })
          })
          item.option = newOptionList;
        }
        item.from_platform = item.shop_type;
        item.client_remark = item.note;
        item.order_detail_tag = item.inventory_tag;
        item.client_remark = item.note;
        item.order_detail_replace = [];
        this.newList.push(item);
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.addInventoryGoodsToOrderDialogVisible = false;
      this.commodityTotal();
    },
    //展示商品的附加服务
    showGoodsOption(item, itemIndex) {
      this.itemIndex = itemIndex;
      if (item.getOptionStatus === false) {
        let optionList = [];
        this.$api.optionList({
          order_detail_id: item.id,
          need_desc: true
        }).then((res) => {
          res.data.forEach((item) => {
            item.total = 0;
          })
          optionList = res.data;
          if (item.option.length > 0) {
            item.option.forEach((item1) => {
              optionList.forEach((optionItem) => {
                if (item1.name == optionItem.name) {
                  optionItem.num = item1.num;
                  optionItem.checked = item1.checked;
                  optionItem.price = item1.price;
                }
              })
            })
          }
          item.option = optionList;
          item.getOptionStatus = true;
          this.updateOptionData(item)
        });
      } else {
        this.updateOptionData(item)
      }
    },
    //附加服务数据处理
    updateOptionData(item) {
      this.optionPrice = 0;
      item.option.forEach((optionItem) => {
        if (Number(optionItem.num) > 0 && optionItem.checked === true) {
          if (optionItem.price_type === 1) {
            optionItem.total = (optionItem.num * Number(item.is_now_data.price)) * (optionItem.price / 100);
            this.optionPrice += Number(optionItem.total)
          }
          if (optionItem.price_type === 0) {
            optionItem.total = Number(optionItem.num) * Number(optionItem.price);
            this.optionPrice += Number(optionItem.total)
          }
        }
      })
      this.timer = new Date().getTime();
      this.showGoodsOptionList = this.$fun.deepClone(item.option);
      this.newShowGoodsOptionDialogVisible = true;
    },
    //保存商品属性编辑
    saveGoodsDetail(type, arr) {
      let str = '';
      let newDetailList = [];
      let pic = '';
      let status = false
      if (type === 1) {
        arr.forEach((item) => {
          if (item.valueC[item.active].picUrl !== '') {
            pic = item.valueC[item.active].picUrl
          }
          newDetailList.push({
            key: item.keyC,
            value: item.valueC[item.active].name
          })
          str += item.valueC[item.active].name + '㊖㊎'
        })
        str = str.substr(0, str.length - 2);
        if (this.goodsInfo.goodsInfo.goodsInventory > 0) {
          this.goodsInfo.goodsInfo.goodsInventory.forEach((item) => {
            if (str === item.keyC && (this.goodsInfo.num > item.startQuantity || this.goodsInfo.num === item.startQuantity)) {
              this.goodsInfo.price = item.price;
            }
          })
        }
      } else {
        var sarr = arr.sort();
        for (let i = 0; i < sarr.length; i++) {
          if (i !== sarr.length - 1) {
            if (sarr[i].key == sarr[i + 1].key) {
              status = true;
              break;
            }
          }
        }
      }
      if (status === true) {
        this.$message.error('チェック便宜上、属性制限しております。必要な場合では担当者までご連絡くださいませ。');
        return
      }
      this.newList.forEach((item) => {
        if (this.goodsInfo.id === item.id) {
          item.detail = type === 1 ? newDetailList : arr;
          if (pic !== '') {
            item.pic = pic;
          }
        }
      })
      this.goodsDetailDialogVisible = false;
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
      this.commodityTotal();
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.newList.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
    },
    //获取要选择备选链接的商品
    getSelectAlternativeLinkGoods(item) {
      item.order_detail_replace.forEach((replaceItem) => {
        replaceItem.totalPrice = (Number(replaceItem.confirm_num) * Number(replaceItem.confirm_price)) + Number(replaceItem.confirm_freight)
      })
      this.goodsInfo = item;
      this.selectAlternativeLinkDialogVisible = true;
    },
    // 删除商品
    delOrder() {
      if (this.checked === true) {
        this.newList = [];
        this.checked = false;
      } else {
        for (let i = this.newList.length - 1; i >= 0; i--) {
          if (this.newList[i].checked === true) {
            this.newList.splice(i, 1);
          }
        }
      }
      this.commodityTotal();
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
    },
    //获取商品的信息
    getItemMessage(item) {
      this.goodsInfo = item;
    },
    //图片上传成功
    handleAvatarSuccess(res, file) {
      this.newList.forEach((item) => {
        if (this.goodsInfo.id === item.id) {
          item.pic = res.data;
          this.loading.close();
        }
      })
      this.deepCloneNewList = this.$fun.deepClone(this.newList);
    },
    //上传商品图片前的处理
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    // 单选
    radio(i) {
      let flag = this.newList[i].checked == true;
      this.checkedList = [];
      if (flag) {
        this.newList[i].checked = true;
      } else {
        this.newList[i].checked = false;
      }
      this.newList.forEach((item) => {
        if (item.checked) {
          this.checkedList.push(item)
        }
      })
      this.$forceUpdate();
      this.isCheckedAll();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.newList.every((item) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$forceUpdate();
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.goodsTotalPrice = 0;
      this.goodsTotalServiceCharge = 0;
      this.RMBTotalPrice = 0;
      this.yenTotalPrice = 0;
      this.optionTotalPrice = 0;
      this.goodsTotalNum = 0;
      this.goodNum = 0;
      this.freightTotalPrice = 0;
      let optionList = [];
      this.goodsTotalServiceChargeDetailsList = [];
      this.newList.forEach((item, i) => {
        if (item.detail != null) {
          if (this.updateOrderStatus === true) {
            let str = '';
            item.detail.forEach((detailItem) => {
              str += detailItem.value + '㊖㊎';
            })
            str = str.substr(0, str.length - 2);
            if (item.goodsInfo != undefined && item.goodsInfo.goodsInventory.length > 0) {
              item.goodsInfo.goodsInventory.forEach((goodsInventoryItem) => {
                if (str === goodsInventoryItem.keyC) {
                  goodsInventoryItem.valueC.forEach((valueItem) => {
                    if (item.is_client_send_data.num >= valueItem.startQuantity) {
                      item.is_now_data.price = valueItem.price
                    }
                  })
                }
              })
            }
          }
        }
        this.updateOrderStatus === true ? this.goodsTotalPrice += Number(item.is_now_data.price) * item.is_client_send_data.num : this.goodsTotalPrice += Number(item.is_now_data.price) * item.is_now_data.num
        item.optionPrice = 0;
        if (item.option != null) {
          if (item.option.length > 0) {
            item.option.forEach((optionItem) => {
              if (optionItem.checked === true && optionItem.num >= 1 && optionItem.price_type === 0) {
                this.optionTotalPrice += optionItem.num * Number(optionItem.price);
                item.optionPrice += optionItem.num * Number(optionItem.price);
                optionItem.total = optionItem.num * Number(optionItem.price);
                optionList.push(this.$fun.deepClone(optionItem));
              }
              if (optionItem.checked === true && optionItem.price_type === 1) {
                let value = (Number(item.is_now_data.price) * optionItem.num) * (Number(optionItem.price) / 100);
                this.optionTotalPrice += value
                item.optionPrice += value
                optionItem.total = value
                optionList.push(this.$fun.deepClone(optionItem));
              }
            })
          }
        }
        if (this.datas.order_amount.discounts_detail.length > 0) {
          item.service_rate = 0
        }
        this.goodsTotalNum += item.is_client_send_data.num;
        if (this.updateOrderStatus === true) {
          this.goodNum += Number(item.is_client_send_data.num);
          item.service_rate_value = (Number(item.is_now_data.price) * item.is_client_send_data.num) * Number(item.service_rate);
          item.totalPrice = (item.is_client_send_data.num * Number(item.is_now_data.price)) + item.optionPrice + Number(item.service_rate_value) + Number(item.is_now_data.freight)
        } else {
          this.goodNum += Number(item.is_now_data.num);
          item.service_rate_value = (Number(item.is_now_data.price) * item.is_now_data.num) * Number(item.service_rate);
          item.totalPrice = (item.is_now_data.num * Number(item.is_now_data.price)) + item.optionPrice + Number(item.service_rate_value) + Number(item.is_now_data.freight)
        }
        this.goodsTotalServiceCharge += item.service_rate_value;
        this.freightTotalPrice += Number(item.is_now_data.freight);
        this.goodsTotalServiceChargeDetailsList.push({
          No: i + 1,
          service_rate: this.datas.order_amount.discounts_detail.length == 0 ? this.$fun.returnServiceRate(item.service_rate) : 0,
          value: this.datas.order_amount.discounts_detail.length == 0 ? item.service_rate_value : 0
        })
      });
      if (optionList.length > 0) {
        let end = [];
        end.push(optionList[0]);
        for (let i = 1; i < optionList.length; i++) {
          optionList[i].num = Number(optionList[i].num);
          let j = 0
          for (; j < end.length; j++) {
            // 6. 判断两个数组中的id是否相同
            if (optionList[i].name === end[j].name) {
              // 7.相等就将我们比较的对象 `arr[i]` 存储到  相同id对象的list 中
              end[j].num = Number(end[j].num);
              end[j].num += optionList[i].num;
              end[j].total += optionList[i].total
              // *** 8.这里要理解，我们每次遍历，其实都是处理  `arr[i]` ，而上面的逻辑已经处理了，所以终止后续的循环 break;
              break;
            }
          }
          if (j === end.length) {
            end.push(optionList[i])
          }
        }
        this.selectOptionList = end;
      }
      if (this.updateOrderStatus === true) {
        this.RMBTotalPrice = this.goodsTotalPrice + this.goodsTotalServiceCharge + this.optionTotalPrice + this.freightTotalPrice + Number(this.datas.other_price);
        this.yenTotalPrice = Number(this.datas.exchange_rate) > 0 ? this.RMBTotalPrice * Number(this.datas.exchange_rate) : this.RMBTotalPrice * Number(this.exchangeRate)
      } else {
        this.freightTotalPrice = this.datas.order_amount.china_logistics_price_rmb;
        this.goodsTotalPrice = this.datas.order_amount.goods_amount_rmb;
        this.RMBTotalPrice = this.datas.order_amount.sum_amount;
        this.yenTotalPrice = this.datas.order_amount.sum_amount_jpy;
        if (this.datas.order_amount.discounts_detail.length === 0 && this.couponChecked === false) {
          this.goodsTotalServiceCharge = this.datas.order_amount.service_amount_rmb;
          this.RMBTotalPrice = this.datas.order_amount.sum_amount;
          this.yenTotalPrice = this.datas.order_amount.sum_amount_jpy;
        } else if (this.datas.order_amount.discounts_detail.length > 0) {
          this.goodsTotalServiceCharge = 0;
          this.RMBTotalPrice = Number(this.datas.order_amount.sum_amount) - Number(this.datas.order_amount.discounts_amount);
          this.yenTotalPrice = Number(this.datas.order_amount.sum_amount_jpy) - Number(this.datas.order_amount.discounts_amount_jpy);
        }
      }
    },
    //修改商品的规格
    getGoodsDetail(detail, item) {
      if (item.goodsInfo == undefined) {
        this.$api.getGoodsAttribute({
          shop_type: item.from_platform,
          goods_id: item.goods_id
        }).then((res) => {
          if (res.data.length > 0) {
            item.goodsInfo = {
              goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
              specification: this.$fun.deepClone(res.data[0].specification),
            }
            res.data[0].specification.forEach((specificationItem) => {
              foo:for (let j = 0; j < detail.length; j++) {
                for (let i = 0; i < specificationItem.valueC.length; i++) {
                  if (specificationItem.keyC === detail[j].key && specificationItem.valueC[i].name === detail[j].value) {
                    specificationItem.active = i;
                    specificationItem.oldActive = this.$fun.deepClone(i);
                    break foo;
                  } else {
                    specificationItem.active = 0;
                    specificationItem.oldActive = this.$fun.deepClone(0);
                  }
                }
              }
            })
          } else {
            item.goodsInfo = {
              goodsInventory: [],
              specification: [],
            }
          }
          this.timer = new Date().getTime();
          this.goodsInfo = item;
          this.goodsDetailList = res.data[0].specification;
          this.goodsDetailDialogVisible = true;
        })
      } else {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem) => {
            foo:for (let j = 0; j < detail.length; j++) {
              for (let i = 0; i < specificationItem.valueC.length; i++) {
                if (specificationItem.keyC === detail[j].key && specificationItem.valueC[i].name === detail[j].value) {
                  specificationItem.active = i;
                  specificationItem.oldActive = this.$fun.deepClone(i);
                  break foo;
                } else {
                  specificationItem.active = 0;
                  specificationItem.oldActive = this.$fun.deepClone(0);
                }
              }
            }
          })
        }
        this.timer = new Date().getTime();
        this.goodsInfo = item;
        this.goodsDetailList = item.goodsInfo.specification;
        this.goodsDetailDialogVisible = true;
      }
    },
    //弹出从购物车追加商品
    showAddCartGoodsToOrderDialog(val) {
      if (this.newList.length < 100) {
        let str = ''
        this.newList.forEach((item) => {
          str += item.id + ','
        })
        str = str.substr(0, str.length - 1);
        this.addTimer = new Date().getTime();
        this.ids = str;
        val === 1 ? this.addCartGoodsToOrderDialogVisible = true : this.addInventoryGoodsToOrderDialogVisible = true;
      } else {
        this.$message.error(this.$fanyi("一个订单最多添加100个商品"));
      }
    },
    //修改订单
    updateOrderMessageAndStatus() {
      if (this.newList.length > 100) {
        return this.$message(this.$fanyi("一个订单最多添加100个商品"));
      }
      if (this.$refs.SpecialNote.selectValue === '') {
        this.$message.error(this.$fanyi("物流失效，请重新选择物流"));
        return
      }
      let dataList = [];
      let str = '';
      let sku_id = '';
      let spec_id = '';
      let status = false;
      this.newList.forEach((item) => {
        if (item.goodsInfo != undefined && item.detail != null) {
          if (item.goodsInfo.goodsInventory.length > 0) {
            str = '';
            item.detail.forEach((items) => {
              str += items.value + '㊖㊎'
            })
            str = str.substr(0, str.length - 2);
            item.goodsInfo.goodsInventory.forEach((goodsInventoryItem) => {
              if (str === goodsInventoryItem.keyC) {
                sku_id = goodsInventoryItem.valueC[0].skuId;
                spec_id = goodsInventoryItem.valueC[0].specId
              }
            })
          }
        } else {
          sku_id = item.sku_id;
          spec_id = item.spec_id;
        }
        let tagList = [];
        let optionList = [];
        item.option.forEach((optionItem) => {
          if (Number(optionItem.num) > 0 && optionItem.checked === true) {
            optionItem.total = undefined
            optionList.push(optionItem)
          }
        })
        if (item.order_detail_tag.length > 0) {
          item.order_detail_tag.forEach((tagItem) => {
            tagList.push({
              type: tagItem.type,
              no: tagItem.no,
              goods_no: tagItem.goods_no,
              type_translate: tagItem.type_translate
            })
          })
        }
        dataList.push({
          id: item.pushStatus ? undefined : item.id,
          cart_id: item.cart_id != undefined ? item.cart_id : undefined,
          goods_id: item.goods_id,
          goods_title: item.goods_title,
          price: item.is_now_data.price,
          num: Number(item.is_client_send_data.num),
          order_detail_tag: item.order_detail_tag.length > 0 ? tagList : undefined,
          pic: item.pic,
          detail: JSON.stringify(item.detail),
          sku_id: sku_id,
          spec_id: spec_id,
          shop_id: item.shop_id,
          for_sn: item.for_sn,
          shop_name: item.shop_name,
          option: optionList.length > 0 ? JSON.stringify(optionList) : '[]',
          from_platform: item.from_platform,
          client_remark: item.client_remark,
        })
      })
      for (let i = 0; i < dataList.length; i++) {
        if (Number(dataList[i].num) < 1) {
          status = true;
          break;
        }
      }
      if (status === true) {
        this.$message.error(this.$fanyi("提货数量不能为零"));
        return
      }
      let datas = {
        create_type: 'send',
        order_sn: this.datas.order_sn,
        logistics_id: this.$refs.SpecialNote.selectValue,
        client_remark: this.$refs.SpecialNote.client_remark,
        follow_remark: this.$refs.SpecialNote.follow_remark,
        order_detail: dataList,
        order_file: this.$refs.SpecialNote.fileList
      };
      this.$api.orderEdit(datas).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
        setTimeout(() => {
          this.$fun.routerToPage("/user/order?status=offer");
        }, 500)
      });
    },
    //加入商品库
    goodPushInventory() {
      if (this.newList.length == 0) {
        return this.$message.error(this.$fanyi("请至少添加一个商品"));
      }
      let arr = [];
      this.newList.forEach((item) => {
        if (item.checked === true) {
          let optionList = [];
          let tagList = [];
          let sku_id = '';
          let spec_id = '';
          item.option.forEach((optionItem) => {
            if (Number(optionItem.num) > 0 && optionItem.checked === true) {
              optionItem.total = undefined
              optionList.push(optionItem)
            }
          })
          if (this.$route.query.order_sn !== undefined) {
            if (item.order_detail_tag.length > 0) {
              item.order_detail_tag.forEach((tagItem) => {
                if (tagItem.no !== '' || tagItem.goods_no !== '') {
                  tagList.push({
                    type: tagItem.type,
                    no: tagItem.no,
                    goods_no: tagItem.goods_no
                  })
                }
              })
            }
          } else {
            if (item.order_detail_tag.length > 0) {
              item.order_detail_tag.forEach((tagItem) => {
                if (tagItem.no !== '' || tagItem.goods_no !== '') {
                  tagList.push({
                    type: tagItem.type,
                    no: tagItem.no,
                    goods_no: tagItem.goods_no
                  })
                }
              })
            }
          }
          if (item.detail != null && item.goodsInfo != undefined) {
            let str = '';
            if (item.goodsInfo.goodsInventory.length > 0) {
              str = '';
              item.detail.forEach((items) => {
                str += items.value + '㊖㊎'
              })
              str = str.substr(0, str.length - 2);
              item.goodsInfo.goodsInventory.forEach((goodsInventoryItem) => {
                if (str === goodsInventoryItem.keyC) {
                  sku_id = goodsInventoryItem.valueC[0].skuId;
                  spec_id = goodsInventoryItem.valueC[0].specId
                }
              })
            }
          } else {
            sku_id = item.sku_id;
            spec_id = item.spec_id;
          }
          arr.push({
            shop_type: item.from_platform,
            shop_id: item.shop_id,
            shop_name: item.shop_name,
            goods_id: item.goods_id,
            goods_title: item.goods_title,
            img_url: item.pic,
            detail: JSON.stringify(item.detail),
            option: optionList.length > 0 ? JSON.stringify(optionList) : '[]',
            num: 1,
            price: item.is_client_send_data.price,
            for_sn: item.for_sn != undefined ? item.for_sn : undefined,
            sku_id: sku_id,
            spec_id: spec_id,
            note: item.client_remark,
            inventory_tag: tagList.length > 0 ? tagList : undefined
          })
        }
      })
      if (arr.length === 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'));
        return
      }
      this.$api.favoriteInventoryBatchSave({data: arr}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin.scss";
@import "../css/orderDetails.scss";
@import "../css/obligationOrderDetailsPage.scss";

.backgroundFAF2F2 {
  background: #FAF2F2 !important;
}
</style>
