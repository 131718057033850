<template>
  <div class="statistics">

    <div class="row">
      <div class="key">{{ $route.name == 'deliveryDetails' ? '发货数' : '買付数量' }}:</div>
      <div class="val">{{ statistics.c_num }}</div>
    </div>
    <div class="row">
      <div v-if="$route.query.id == undefined" class="key">総額:</div>
      <div v-else class="key">商品金額:</div>
      <div class="val">{{ statistics.amount.toFixed(2) }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    checkedList: {type: Array},
    edit: {type: Boolean},
  },
  computed: {
    statistics() {
      let total = {
        c_num: 0,
        amount: 0,
      }
      this.checkedList.forEach(item => {
        if (this.$route.name == 'OrderDetails') {
          if (this.$route.query.id != undefined) {
            total.c_num += item.num - 0
            total.amount += item.num * Number(item.price)
          } else {
            if (this.edit) {
              total.c_num += item.is_client_send_data.num - 0
              total.amount += (item.is_client_send_data.num * item.is_client_send_data.price + (item.is_client_send_data.freight - 0))
            } else {
              total.c_num += item.is_now_data.num - 0
              total.amount += (item.is_now_data.num * item.is_now_data.price + (item.is_now_data.freight - 0))
            }
          }
        } else {
          total.c_num += item.value - 0;
          total.amount += (item.value * item.confirm_price + (item.confirm_freight - 0))
        }
      })
      return total
    }
  },
  data() {
    return {};
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.row {
  display: flex;
  margin-bottom: 5px;
  color: #000;

  .key {
    width: 60px;
    margin-right: 10px
  }

  .val {
    color: #B4272D;
  }
}

/deep/ .el-popover {
  background-color: pink !important;
}
</style>